import React from 'react';

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
      <footer className="bg-slate-100 p-4 shadow-lg">
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex items-center">
            <a href="/" className="flex items-center">
              <img src={`${process.env.PUBLIC_URL}/TK04.png`} className="h-15 w-40 ml-6" alt="logo" />
            </a>
          </div>
          <div className="text-center w-full">
            <p className="text-sm">
              © Copyright {currentYear} Temariko | All Rights Reserved
            </p>
          </div>
        </div>
      </footer>
    );
}

export default Footer;