// import React from 'react';
// import { Outlet } from 'react-router-dom';
// import Header from './components/header';
// import Footer from './components/footer';

// function App() {
//   return (
//     <div>
//       <Header />
//       <main className="p-4">
//         <Outlet />
//       </main>
//       <Footer />
//     </div>
//   );
// }

// export default App;




import React from 'react';
import Header from './components/header';
import Footer from './components/footer';
import Home from './pages/index';
import About from './pages/about';
import Contact from './pages/contact';

function App() {
  return (
    <div>
      <Header />
      <main>
        <section id="home">
          <Home />
        </section>
        <section id="contact">
          <Contact />
        </section>
        <section id="about">
          <About />
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default App;

