import React from 'react';
// import logo from '../logo.svg';
// import pic1 from '../pic1.jpg';

function Home() {
  return (
    <div className="mt-10 bg-white">
      <div className="flex flex-col lg:flex-row p-10 bg-slate-100 items-center lg:items-start">
        <div className="w-full lg:w-1/2 p-10 ml-0 lg:ml-20 mt-10 lg:mt-40 mt-40">
          <h2 className="text-4xl font-bold text-cyan-600">Simplify School Management, Empower Education</h2>
          <p className="text-lg mt-2">
            A comprehensive school management system designed to save time, reduce stress, and improve educational outcomes.
            Our school management software is a comprehensive platform that unifies administrators, 
            teachers, students, and parents in a digital environment. It goes beyond being a tool; it's 
            a catalyst for enhancing the entire educational ecosystem. Real-time access to academic 
            information and resources is at the core of our mission. 
          </p>
        </div>
        
        <div className="w-full lg:w-1/2 p-4 mt-10 ml-20">
          <img src={`${process.env.PUBLIC_URL}/105.png`} className="w-3/4 h-auto rounded-lg" alt="logo" />
          {/* <img src={logo} alt="Sample" className="w-full h-auto rounded-lg" /> */}
        </div>
      </div>

      <div className="p-10">
        <h3 className="text-4xl text-center text-cyan-600 font-semibold mb-8">Features of School Management Software</h3>
        <div className="flex justify-center">
          {/* This (grid-cols-1 md:grid-cols-2) ensures that the layout remains responsive, with one column on small screens and two columns on medium and larger screens. */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8"> 
            <ul className="list-none text-lg space-y-4">
              <li className="border border-cyan-500 rounded-lg p-4 shadow-md max-w-sm">
                Student and Teacher Management
              </li>
              <li className="border border-cyan-500 rounded-lg p-4 shadow-md max-w-sm">
                Class Management
              </li>
              <li className="border border-cyan-500 rounded-lg p-4 shadow-md max-w-sm">
                Grade Management and Reporting
              </li>
              <li className="border border-cyan-500 rounded-lg p-4 shadow-md max-w-sm">
                Homework and Assignment Tracking
              </li>
            </ul>

            <ul className="list-none text-lg space-y-4">
              <li className="border border-cyan-500 rounded-lg p-4 shadow-md max-w-sm">
                Parent-Teacher Communication Portal
              </li>
              <li className="border border-cyan-500 rounded-lg p-4 shadow-md max-w-sm">
                School Announcement and News
              </li>
              <li className="border border-cyan-500 rounded-lg p-4 shadow-md max-w-sm">
                Attendance Tracking
              </li>
              <li className="border border-cyan-500 rounded-lg p-4 shadow-md max-w-sm">
                Timetable Scheduling
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="p-20">
        <h3 className="text-4xl text-center text-cyan-600 font-semibold mb-20">Why Temariko is the best school management system?</h3>
        <div className="flex justify-center">
          {/* This (grid-cols-1 md:grid-cols-2) ensures that the layout remains responsive, with one column on small screens and two columns on medium and larger screens. */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8"> 
            <ul className="list-none text-lg space-y-8">
              <li className="p-4 max-w-sm">
                <p className="text-center text-2xl font-semibold">Cloud Based Software </p>
                <p className="text-center">Temariko is school software that is always online, you can access it from anywhere, anytime. We will take care of your data and backups.</p>
              </li>
              <li className="p-4 max-w-sm">
                <p className="text-center text-2xl font-semibold"> Regular Updates & Support </p>
                <p className="text-center">We add new and awesome features regularly to make our school administrative software unmatchable. 
                We offer 24/7 support for users.</p>
              </li>
            </ul>

            <ul className="list-none">
              <li> <img src={`${process.env.PUBLIC_URL}/102.png`} className="w-3/4 h-auto rounded-lg" alt="logo" /> </li>
              <li className="p-4 mt-10 max-w-sm">
                <p className="text-center text-2xl font-semibold"> Available In English, Amharic, and Afan Oromo</p>
                <p className="text-center">Temariko school management software comes in English, Amharic, and Afan Oromo. You can manage your educational institute with your prefered language.</p>
              </li>
            </ul>

            <ul className="list-none text-lg space-y-4">
              <li className="p-4 max-w-sm">
                <p className="text-center text-2xl font-semibold"> Responsive Web Design </p>
                <p className="text-center">You can use our school management software on any device, like Mobile, Tablet, Laptop, or desktop due to its responsive design.</p>
              </li>
              <li className="p-4 max-w-sm">
                <p className="text-center text-2xl font-semibold"> Fast, Secure & Easy </p>
                <p className="text-center">We use advanced tools and technologies to build up this free school software. It is super fast, secure, reliable, and easy to use and manage.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row p-10 items-center lg:items-start mt-8">
        <div className="lg:w-1/2 p-4 ml-20">
          <img src={`${process.env.PUBLIC_URL}/104.png`} alt="Sample" className="w-3/4 h-auto" />
        </div>

        <div className="lg:w-1/2 p-10">
          <h2 className="text-3xl font-bold text-cyan-600 mt-20 mb-10">Separate Portals for Every User Role</h2>
          <p className="text-lg mt-2">
            Our school management system comes with a separate portal for every user role. 
          </p>
          <ul className="list-disc list-inside text-lg mt-2 marker:text-cyan-600">
            <li>Admin Portal: Full control and access to all system features.</li>
            <li>Management Staff Portal: Efficient tools for managing daily operations.</li>
            <li>Teacher Portal: Easy access to class schedules, attendance, and grading.</li>
            <li>Parent Portal: Stay informed about your child's progress and school events.</li>
            <li>Student Portal: Access to grades, schedules, and school communications.</li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row p-10 items-center lg:items-start mt-8">
        <div className="lg:w-1/2 p-10">
          <h2 className="text-3xl font-bold text-cyan-600 mt-40">Web & Mobile Application</h2>
          <p className="text-lg mt-2">
            Our school management system is accessible anytime, anywhere, through a web and mobile apps. Whether you're in the office or on the go, managing your school has never been easier.
            You can download and install our mobile application on Google Play and App Store .
          </p>
        </div>
        <div className="lg:w-1/2 p-4 ml-20">
          <img src={`${process.env.PUBLIC_URL}/07.png`} alt="Sample" className="w-3/4 h-auto rounded-xl mask-gradient" />
        </div>
      </div>

    </div>
  );
}

export default Home;



