import React, { useState } from 'react';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    // <header className="bg-gradient-to-r from-blue-500 to-purple-600 p-4 shadow-lg">
    <header className="fixed top-0 left-0 w-full p-5 bg-slate-100 z-50">
    {/* <header className="fixed top-0 left-0 w-full p-5 bg-gradient-to-r from-blue-500 to-purple-600 z-50"> */}
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <a href="/" className="flex items-center">
            <img src={`${process.env.PUBLIC_URL}/TK04.png`} className="h-15 w-40 ml-12" alt="logo" />
          </a>
        </div>

        <div className="block lg:hidden">
          <button onClick={() => setIsOpen(!isOpen)} className="text-black focus:outline-none">
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>

        <nav
          className={`${
            isOpen ? 'block bg-slate-200' : 'hidden'
          } lg:flex lg:items-center lg:space-x-12 fixed lg:relative top-0 left-0 lg:left-auto transform lg:transform-none w-64 lg:w-auto h-full lg:h-auto lg:bg-transparent p-4 lg:p-0 z-50`}
        >
          <a href="/" className="text-cyan-600 hover:text-gray-300 text-lg font-bold block lg:inline-block py-2 px-4">
            Home
          </a>
          <a href="#contact" className="text-cyan-600 hover:text-gray-300 text-lg font-bold block lg:inline-block py-2 px-4">
            Contact
          </a>
          <a href="#about" className="text-cyan-600 hover:text-gray-300 text-lg font-bold block lg:inline-block py-2 px-4">
            About
          </a>
        </nav>
      </div>
    </header>
  );
}

export default Header;




// import React from 'react';
// import { Link } from 'react-router-dom';
// import logo from '../logo.svg';

// function Header() {
//   return (
//     <header className="bg-gradient-to-r from-blue-500 to-purple-600 p-4 shadow-lg">
//       <div className="container mx-auto flex items-center justify-between">
//         <div className="flex items-center">
//             <Link to="/" className="flex items-center">
//                 <img src={logo} className="h-10 w-10 mr-3" alt="logo" />
//                 <span className="text-white text-2xl font-bold">Temariko</span>
//             </Link>
//         </div>

//         <nav className="absolute left-1/2 transform -translate-x-1/2 flex space-x-12">
//             <Link className="text-white hover:text-gray-300 text-lg font-bold" to="/">Home</Link>
//             <Link className="text-white hover:text-gray-300 text-lg font-bold" to="/contact">Contact</Link>
//             <Link className="text-white hover:text-gray-300 text-lg font-bold" to="/about">About</Link>
//         </nav>

//         <div className="flex items-center">
//             <Link to="/signin" className="text-white bg-blue-500 hover:bg-blue-800 text-lg font-bold py-2 px-4 rounded">
//                 Login
//             </Link>
//         </div>
//       </div>
//     </header>
//   );
// }

// export default Header;