import React from 'react';

function About() {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="p-10 w-full lg:w-1/2 rounded-lg shadow-md">
        <h1 className="text-4xl text-center font-bold text-cyan-600">About Us</h1>
        <div className="p-5 lg:p-20">
          <p className="text-lg mb-10">Temariko School Management System is a revolutionary solution designed to 
            transform school administration, empower teachers, and enrich the academic 
            experience for students and parents. Our user-friendly platform brings administrators, 
            teachers, students, and parents together in a unified digital environment, streamlining 
            communication and enhancing overall efficiency in school management.  
          </p>

          <p className="text-lg mb-10"> 
            Traditional school management systems often involve complex, paper-based processes, 
            leading to inefficiencies, communication gaps, and a lack of real-time information. 
            Administrators, teachers, students, and parents face challenges in accessing and 
            managing crucial data seamlessly, impacting the overall effectiveness of educational 
            institutions. 
          </p>

          <p className="text-lg mb-10">
            Temariko is is a comprehensive solution designed to address the challenges faced by educational institutions. 
            It is a leading low-cost all-in one school management system for all types of schools. 
            In a world where processes have become digital, SiyahaTech offers instant data and communications at your fingertips. 
            This system is exclusively geared towards K-12 school administration and includes a reliable mobile app and admin web 
            dashboard for accessing student info and staying connected with parents. 
          </p>

          <p className="text-lg">
            Explore the Temariko School Management Software and discover how it can revolutionize 
            your school experience. Feel free to reach out with any questions or to schedule a 
            demonstration. Let's make school management effortless and enjoyable together! 
          </p>

          <div className="text-center mt-10">
            <a href="#contact" className="bg-cyan-600 text-white px-6 py-3 rounded-lg hover:bg-cyan-700 transition duration-300">
              Get A Quote
            </a>
          </div>

        </div>

      </div>
    </div>
  );
}

export default About;